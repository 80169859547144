import { IPopup } from "@/models";
import { Toast } from "primereact";
import { sendPopup } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useSendPopupData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: sendPopupIsLoading,
        isSuccess: sendPopupIsSuccess,
    } = useMutation(async (popup: IPopup) => await sendPopup(popup), {
        onSuccess: () => {
            queryClient.invalidateQueries("popupHistory");
            toastSuccess(toast, "Popup created");
        },
        onError: (error: any) => {
            let errorMessage = "Popup not created";
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            toastError(toast, `${errorMessage}`);
        },
    });
    return {
        mutate,
        sendPopupIsLoading,
        sendPopupIsSuccess,
    };
};
