import { IPopup } from "@/models";
import { axiosInstance } from "@/api/axios.instance";
import { STATUS } from "@/constants";

export const getPopupHistory = async (): Promise<IPopup[]> => {
    const response = await axiosInstance.get("/customer/popup/getAllPopUp");
    return response.data;
}

export const sendPopup = async (popup: IPopup) =>
    await axiosInstance.post(`/customer/popup/createPopUp`, popup);

export const updatePopup = async (popup: IPopup) =>
    await axiosInstance.put(`/customer/popup/updatePopUp`, popup);

export const updatePopupStatus = async (popupId: bigint, status: STATUS) =>
    await axiosInstance.put(`/customer/popup/updateStatus`, null, {
        params: {
            popUpId: popupId,
            status: status,
        }
    });

export const deletePopup = async (popupId: bigint) =>
    await axiosInstance.delete(`/customer/popup/delete/${popupId}`);
