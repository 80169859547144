export enum GENDER {
    MAN = "MAN",
    WOMAN = "WOMAN",
}

export enum STATUS {
    INACTIVE = "INACTIVE",
    ACTIVE = "ACTIVE",
}

export enum ORDERS_STATUS {
    NEW = "NEW",
    PREPARING = "PREPARING",
    SENT = "SENT",
    CANCELED = "CANCELED",
    RETURN = "RETURN",
    FINISHED = "FINISHED",
    RESENT_NEW = "RESENT_NEW",
}

export enum ROLE {
    ADMIN = "ADMIN",
    MANAGER = "MANAGER",
    OPERATOR = "OPERATOR",
}

export enum MBI_TYPES {
    CMP = "CMP",
    PRD = "PRD",
    URL = "URL",
    VIW = "VIW",
    ROT = "ROT",
}

export enum CAMPAIGN_TYPES {
    SC = "SC",
    DW = "DW",
    RC = "RC",
    VC = "VC",
    OT = "OT",
    DRW = "DRW",
    TBD = "TBD",
    DRT = "DRT",
}

export enum POPUP_TYPES {
    TXT = "TXT",
    VID = "VID",
    PIL = "PIL",
    PIR = "PIR",
    FSIL = "FSIL",
    FSIR = "FSIR",
}

export enum PRODUCT_TYPES {
    DIGITAL = "DIGITAL",
    PHYSICAL = "PHYSICAL",
    DRAW = "DRAW_TICKET",
}

export enum CONFIG_VARS {
    LIGHT = "light",
    DARK = "dark",
    LIGHT_THEME = "lara-light-indigo",
    DARK_THEME = "lara-dark-indigo",
    SCALE = 14,
}
