import { useState, useRef, useEffect } from "react";
import {
    DataTableToolbar,
    DataTableSearchHeader,
    PopupDialog,
    DataTableActionTemplate,
    DataTableDeleteDialog,
    DataTableUpdateDialog,
} from "@/components";
import { IPopup } from "@/models";
import { Button, Column, ColumnFilterElementTemplateOptions, DataTable, Dropdown, Paginator, PaginatorPageState, Toast, Tooltip } from "primereact";
import { ROLE, STATUS, initialPopup, statusTypes } from "@/constants";
import {
    useRole,
    useSendPopupData,
    useGetPopupHistoryData,
    useUpdatePopupData,
    useDeletePopupData,
    useUpdatePopupStatusData,
} from "@/hooks";
import { useQueryClient } from "react-query";

const PopupHistoryDataTable = () => {
    const { role } = useRole();
    const [popup, setPopup] = useState<IPopup>(initialPopup);
    const [popupDialog, setPopupDialog] = useState(false);
    const [isSendPopup, setIsSendPopup] = useState(false);
    const [selectedPopup, setSelectedPopup] = useState<any>(null);
    const [deletePopupDialog, setDeletePopupDialog] = useState(false);
    const [updatePopupStatusDialog, setUpdatePopupStatusDialog] = useState(false);

    const toast = useRef<Toast>(null);

    const {
        mutate: sendPopupMutate,
        sendPopupIsLoading,
        sendPopupIsSuccess,
    } = useSendPopupData(toast);

    const {
        mutate: updatePopupMutate,
        updatePopupIsLoading,
        updatePopupIsSuccess,
    } = useUpdatePopupData(toast);

    const { mutate: deletePopupMutate } = useDeletePopupData(toast);
    const { mutate: updatePopupStatusMutate } = useUpdatePopupStatusData(toast);

    const { popupHistory, getPopupHistoryIsLoading } = useGetPopupHistoryData();
    const totalRecords = popupHistory?.[0]?.totalElements ?? 0;

    useEffect(() => {
        if (sendPopupIsSuccess || updatePopupIsSuccess) {
            hidePopupDialog();
        }
    }, [sendPopupIsSuccess, updatePopupIsSuccess]);

    const hidePopupDialog = () => {
        setPopupDialog(false);
    };

    const sendPopup = (newPopup: IPopup) => {
        isSendPopup ? sendPopupMutate(newPopup) : updatePopupMutate(newPopup);
    };

    const openSendPopupDialog = () => {
        setPopup(initialPopup);
        setIsSendPopup(true);
        setPopupDialog(true);
    };

    const openEditPopupDialog = (rowData: IPopup) => {
        setPopup({ ...rowData });
        setIsSendPopup(false);
        setPopupDialog(true);
    };

    const openUpdatePopupStatusDialog = (popup?: IPopup) => {
        popup && setPopup(popup);
        setUpdatePopupStatusDialog(true);
    };

    const hideUpdatePopupStatusDialog = () => {
        setUpdatePopupStatusDialog(false);
    };

    const updateProductStatus = () => {
        popup.id && updatePopupStatusMutate({
            popupId: popup.id,
            status: popup.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE
        });
        hideUpdatePopupStatusDialog();
    };

    const openDeletePopupDialog = (popup?: IPopup) => {
        popup && setPopup(popup);
        setDeletePopupDialog(true);
    };

    const hideDeletePopupDialog = () => {
        setDeletePopupDialog(false);
    };

    const deletePopup = () => {
        popup.id && deletePopupMutate(popup.id);
        hideDeletePopupDialog();
    };

    return (
        <>
            <Toast ref={toast} />
            {(role === ROLE.ADMIN || role === ROLE.MANAGER) && (<DataTableToolbar
                openAddDialog={openSendPopupDialog}
            />)}

            <DataTable
                dataKey="id"
                value={popupHistory}
                loading={getPopupHistoryIsLoading}
                title="popup history"
                selection={selectedPopup}
                onSelectionChange={(e: any) => setSelectedPopup(e.value)}
                header={
                    <DataTableSearchHeader
                        title="Pop-up History"
                        filters={null}
                        onChange={(e: any) => { }}
                    />
                }
                rows={25}
                responsiveLayout="scroll"
                emptyMessage="Pop-up messages not found"
            >
                <Column
                    field="title"
                    header={"Title"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="text"
                    header={"Text"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="readUniquePopUpCount"
                    header={"Unique Readed Count"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="readPopUpCount"
                    header={"Readed Count"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="popupType"
                    header={"Pop-up Type"}
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="status"
                    header="Status"
                    sortable
                    filter
                    showFilterMenu={false}
                    filterElement={(options: ColumnFilterElementTemplateOptions) => {
                        return (
                            <Dropdown
                                value={options.value}
                                options={statusTypes}
                                onChange={(e) => options.filterApplyCallback(e.value)}
                                itemTemplate={(option: string) => {
                                    return (
                                        <span
                                            className={`badge status-${option ? option.toLowerCase() : ""}`}
                                        >
                                            {option}
                                        </span>
                                    );
                                }}
                                valueTemplate={(option: string) => {
                                    return (
                                        <span
                                            className={`badge status-${option ? option.toLowerCase() : ""}`}
                                        >
                                            {option}
                                        </span>
                                    );
                                }}
                                placeholder="Select a Status"
                                className="p-column-filter"
                            />
                        );
                    }}
                    body={(rowData: IPopup) => (
                        <Dropdown
                            value={rowData.status}
                            options={statusTypes}
                            onChange={(e) => {
                                e.target.value && openUpdatePopupStatusDialog(rowData);
                            }}
                            itemTemplate={(option: string) => {
                                return (
                                    <span
                                        className={`badge status-${option ? option.toLowerCase() : ""}`}
                                    >
                                        {option}
                                    </span>
                                );
                            }}
                            valueTemplate={(option: string) => {
                                return (
                                    <span
                                        className={`badge status-${option ? option.toLowerCase() : ""}`}
                                    >
                                        {option}
                                    </span>
                                );
                            }}
                            placeholder="Select a Status"
                            style={{ minWidth: "10rem" }}
                        />
                    )}
                />
                <Column
                    body={(rowData: IPopup) => (
                        <>
                            <DataTableActionTemplate
                                openDeleteDialog={() => openDeletePopupDialog(rowData)}
                                openEditDialog={() => openEditPopupDialog(rowData)}
                            />
                            {/* <Tooltip target="#p-info-button" content="User Info" position="bottom" />
                            <Button
                                id="p-info-button"
                                icon="pi pi-info-circle"
                                className="p-button-rounded p-button-secondary m-1"
                                onClick={() => openEditPopupDialog(rowData)}
                            /> */}
                        </>
                    )}
                    exportable={false}
                    style={{ minWidth: "4rem" }}
                ></Column>
            </DataTable>

            <PopupDialog
                popup={popup}
                isSendPopup={isSendPopup}
                sendPopup={sendPopup}
                visible={popupDialog}
                onHide={hidePopupDialog}
                isLoading={isSendPopup ? sendPopupIsLoading : updatePopupIsLoading}
            />

            <DataTableDeleteDialog
                visible={deletePopupDialog}
                data={selectedPopup ?? popup}
                onHide={hideDeletePopupDialog}
                onDelete={deletePopup}
            />

            <DataTableUpdateDialog
                visible={updatePopupStatusDialog}
                data={selectedPopup ?? popup}
                onHide={hideUpdatePopupStatusDialog}
                onUpdate={updateProductStatus}
                text={`Are you sure you want to change status to ${popup.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE}?`}
            />
        </>
    );
};

export default PopupHistoryDataTable;
