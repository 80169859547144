import { getPopupHistory } from "@/services";
import { useQuery } from "react-query";

export const useGetPopupHistoryData = () => {
    const {
        data: popupHistory,
        isLoading: getPopupHistoryIsLoading,
    } = useQuery(["popupHistory"], async () => {
        const popupHistoryData = await getPopupHistory();
        console.log("popupHistoryData", popupHistoryData);
        return popupHistoryData || [];
    });
    return { popupHistory, getPopupHistoryIsLoading };
};
