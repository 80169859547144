import { useFormik } from "formik";
import classNames from "classnames";
import { isFormFieldValid } from "@/utils";
import { Dialog, TabMenu, Toast } from "primereact";
import { DataTableDialogFooter } from "../../common";
import { IPopup, IProduct, ICampaign } from "@/models";
import React, { useEffect, useRef, useState } from "react";
import { POPUP_TYPES } from "@/constants/enums";
import {
    DropdownField,
    ErrorField,
    InputField,
    InputNumberField,
    CalendarField,
} from "@/components/FormFields";
import {
    GeneralImageUploadDialog,
    PopupProductSelectionDialog,
    PopupCampaignSelectionDialog,
} from "@/components";
import { popupSchema } from "@/validations";
import { useUploadGeneralImageData } from "@/hooks";

interface PopupDialogProps {
    visible: boolean;
    popup: IPopup
    isLoading: boolean;
    onHide: () => void;
    sendPopup: (popup: IPopup) => void;
    isSendPopup?: boolean;
}

const items = [
    { label: "Main", icon: "pi pi-fw pi-home" },
    { label: "SecondLanguage", icon: "pi pi-fw pi-language" },
];

const PopupDialog: React.FC<PopupDialogProps> = ({
    visible,
    popup,
    isLoading,
    onHide,
    sendPopup,
    isSendPopup,
}) => {
    const toast = useRef<Toast>(null);

    const [activeIndex, setActiveIndex] = useState(0);

    const [currentImageField, setCurrentImageField] = useState('');

    const [productName, setProductName] = useState('');
    const [campaignName, setCampaignName] = useState('');

    const [imageDialog, setImageDialog] = useState(false);
    const [popupProductSelectionDialog, setPopupProductSelectionDialog] = useState(false);
    const [popupCampaignSelectionDialog, setPopupCampaignSelectionDialog] = useState(false);

    const { mutate: uploadImageMutate, uploadGeneralImageIsLoading, uploadGeneralImageIsSuccess } = useUploadGeneralImageData(toast, (url: string) => {
        if (currentImageField === "imageUrl") {
            formik.setFieldValue("imageUrl", url);
        } else if (currentImageField === "imageUrlScnd") {
            formik.setFieldValue("imageUrlScnd", url);
        }
    });

    useEffect(() => {
        if (uploadGeneralImageIsSuccess) {
            hideImageDialog();
        }
    }, [uploadGeneralImageIsSuccess]);

    const hideDialog = () => {
        onHide();
        formik.resetForm();
    };

    const hideImageDialog = () => {
        setImageDialog(false);
    };

    const hidePopupProductSelectionDialog = () => {
        setPopupProductSelectionDialog(false);
    };

    const hidePopupCampaignSelectionDialog = () => {
        setPopupCampaignSelectionDialog(false);
    };

    const openImageDialog = (field: string) => {
        setCurrentImageField(field);
        setImageDialog(true);
    };

    const openPopupProductSelectionDialog = () => {
        setPopupProductSelectionDialog(true);
    };

    const openPopupCampaignSelectionDialog = () => {
        setPopupCampaignSelectionDialog(true);
    };

    const handleProductSelect = (product: IProduct) => {
        formik.setFieldValue("routeTargetId", product.id);
        setProductName(product.name);
    };

    const handleCampaignSelect = (campaign: ICampaign) => {
        formik.setFieldValue("routeTargetId", campaign.id);
        setCampaignName(campaign.name);
    };

    const saveImage = (file: FileList) => {
        const formData = new FormData();
        formData.append("type", `POPUP_${formik.values.popupType ?? ''}`);
        formData.append("file", file[0]);
        uploadImageMutate({ image: formData });
    };

    const formatDate = (dateString: any) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay değeri 0-11 arası olduğu için +1 ekliyoruz
        const day = String(date.getDate()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day} 00:00:00`;
        return formattedDate;
    };

    const popupOptions = [
        { name: "Text", value: "TXT" },
        { name: "Video", value: "VID" },
        { name: "Link with Partial Image", value: "PIL" },
        { name: "Route with Partial Image", value: "PIR" },
        { name: "Link with Full Screen Image", value: "FSIL" },
        { name: "Route with Full Screen Image", value: "FSIR" },
    ]?.map((popup) => ({
        label: popup.name,
        value: popup.value,
    }));

    const routeOptions = [
        { name: "FAQ", value: "/profile/faq" },
        { name: "Data", value: "/profile/data" },
        { name: "Games", value: "/game" },
        { name: "Orders", value: "/profile/reward" },
        { name: "Bonuses", value: "/campaign" },
        { name: "Rewards", value: "/shop" },
        { name: "Friends", value: "/friend" },
        { name: "Account", value: "/profile" },
        { name: "Profile", value: "/profile/user" },
        { name: "Address", value: "/profile/address" },
        { name: "Language", value: "/profile/language" },
        { name: "Documents", value: "/profile/document" },
        { name: "Contact Us", value: "/profile/contact" },
        { name: "Invite Friends", value: "/profile/invite" },
        { name: "Product Detail", value: "/product" },
        { name: "RC Weekly Bonus", value: "/campaign/bonus" },
        { name: "GamePoint Exchange", value: "/game/trade" },
    ]?.map((route) => ({
        label: route.name,
        value: route.value,
    }));


    const formik = useFormik({
        initialValues: popup,
        enableReinitialize: true,
        validationSchema: popupSchema,
        onSubmit: (data) => {

            const sanitizedData: Partial<Record<keyof typeof data, any>> = { ...data };

            // Loop through keys and convert empty strings to null
            (Object.keys(sanitizedData) as Array<keyof typeof sanitizedData>).forEach((key) => {
                if (sanitizedData[key] === '') {
                    sanitizedData[key] = null;
                }
            });

            sanitizedData.startDate = formatDate(sanitizedData.startDate);
            sanitizedData.endDate = formatDate(sanitizedData.endDate);
            sanitizedData.priority = sanitizedData.priority != null ? sanitizedData.priority : 0;
            sanitizedData.limitValue = sanitizedData.limitValue != null ? sanitizedData.limitValue : 1;
            sanitizedData.inviteCountUpperLimit = sanitizedData.inviteCountUpperLimit != null ? sanitizedData.inviteCountUpperLimit : 0;
            sanitizedData.moneyAmountMin = sanitizedData.moneyAmountMin === null ? 0 : sanitizedData.moneyAmountMin;
            sanitizedData.moneyAmountMax = sanitizedData.moneyAmountMax === 0 || sanitizedData.moneyAmountMax === null ? 999999 : sanitizedData.moneyAmountMax;

            sendPopup(sanitizedData as typeof data);
        },
    });

    const clearValues = () => {
        setProductName('');
        setCampaignName('');
        formik.values.link = null;
        formik.values.routeTargetId = null;
        formik.values.imageUrl = null;
        formik.values.imageUrlScnd = null;
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={visible}
                style={{ width: "450px" }}
                header={"Popup Details"}
                modal
                className="p-fluid"
                footer={
                    <DataTableDialogFooter
                        loading={isLoading}
                        hideDialog={hideDialog}
                        handleSubmit={formik.handleSubmit}
                    />
                }
                onHide={hideDialog}
            >
                <TabMenu
                    model={items}
                    className="mb-5"
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                />
                <form onSubmit={formik.handleSubmit}>
                    {activeIndex === 0 ? (
                        <>

                            <DropdownField
                                id="popupType"
                                value={formik.values.popupType}
                                type="text"
                                required
                                disabled={!isSendPopup}
                                options={popupOptions}
                                onClickCapture={clearValues}
                                onChange={formik.handleChange}
                                labelName="popupType"
                                labelText="Popup Type"
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"popupType"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "popupType"),
                                })}
                            />

                            <InputField
                                id="title"
                                value={formik.values.title ?? ''}
                                type="text"
                                visible={formik.values.popupType === POPUP_TYPES.TXT || formik.values.popupType === POPUP_TYPES.PIR || formik.values.popupType === POPUP_TYPES.PIL}
                                required
                                onChange={formik.handleChange}
                                labelName="title"
                                labelText="Title"
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"title"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "title"),
                                })}
                            />

                            <InputField
                                id="text"
                                value={formik.values.text ?? ''}
                                type="text"
                                visible={formik.values.popupType === POPUP_TYPES.TXT}
                                required
                                onChange={formik.handleChange}
                                labelName="text"
                                labelText="Text"
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"text"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "text"),
                                })}
                            />

                            <InputField
                                id="link"
                                value={formik.values.link ?? ''}
                                type="text"
                                visible={formik.values.popupType === POPUP_TYPES.PIL || formik.values.popupType === POPUP_TYPES.FSIL}
                                required
                                onChange={formik.handleChange}
                                labelName="link"
                                labelText="Link"
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"link"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "link"),
                                })}
                            />

                            <DropdownField
                                id="link"
                                name="link"
                                value={formik.values.link}
                                visible={formik.values.popupType === POPUP_TYPES.PIR || formik.values.popupType === POPUP_TYPES.FSIR || formik.values.popupType === POPUP_TYPES.TXT}
                                required
                                onClickCapture={clearValues}
                                onChange={formik.handleChange}
                                options={routeOptions}
                                labelName="link"
                                labelText="Route"
                                getFormErrorMessage={() => <ErrorField formik={formik} name="link" />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "link"),
                                })}
                            />

                            <InputField
                                id="routeTargetId"
                                value={formik.values.link === '/product' ? productName : campaignName}
                                type="text"
                                visible={(formik.values.popupType === POPUP_TYPES.PIR || formik.values.popupType === POPUP_TYPES.FSIR || formik.values.popupType === POPUP_TYPES.TXT) && (formik.values.link === '/product' || formik.values.link === '/campaign')}
                                required
                                readOnly
                                onChange={formik.handleChange}
                                labelName="routeTargetId"
                                labelText={formik.values.link === '/product' ? "Product" : "Campaign"}
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"routeTargetId"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "routeTargetId"),
                                })}
                                onClick={formik.values.link === '/product' ? openPopupProductSelectionDialog : openPopupCampaignSelectionDialog}
                            />

                            <InputField
                                id="videoUrl"
                                value={formik.values.videoUrl ?? ''}
                                type="text"
                                visible={formik.values.popupType === POPUP_TYPES.VID}
                                required
                                onChange={formik.handleChange}
                                labelName="videoUrl"
                                labelText="Video Url(Youtube)"
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"videoUrl"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "videoUrl"),
                                })}
                            />

                            <InputField
                                id="imageUrl"
                                value={formik.values.imageUrl ?? ''}
                                type="text"
                                visible={formik.values.popupType === POPUP_TYPES.PIL || formik.values.popupType === POPUP_TYPES.FSIL || formik.values.popupType === POPUP_TYPES.PIR || formik.values.popupType === POPUP_TYPES.FSIR}
                                required
                                readOnly
                                labelName="imageUrl"
                                labelText="Image URL"
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"imageUrl"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "imageUrl")
                                })}
                                onClick={() => openImageDialog("imageUrl")}
                            />

                            <InputNumberField
                                id="skipSecond"
                                inputId="skipSecond"
                                type="text"
                                showButtons
                                value={formik.values.skipSecond ?? 0}
                                required
                                onValueChange={formik.handleChange}
                                labelName="skipSecond"
                                labelText="Skip Second"
                                getFormErrorMessage={() => (
                                    <ErrorField formik={formik} name="skipSecond" />
                                )}
                                min={0}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "skipSecond"),
                                })}
                            />

                            <CalendarField
                                id="startDate"
                                labelName="startDate"
                                labelText="Start Date"
                                dateFormat="dd-mm-yy"
                                value={formik.values.startDate ? new Date(formik.values.startDate) : undefined}
                                showIcon
                                required
                                minDate={new Date()}
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"startDate"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "startDate"),
                                })}
                                onChange={formik.handleChange}
                            />

                            <CalendarField
                                id="endDate"
                                labelName="endDate"
                                labelText="End Date"
                                dateFormat="dd-mm-yy"
                                value={formik.values.endDate ? new Date(formik.values.endDate) : undefined}
                                showIcon
                                required
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"endDate"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "endDate"),
                                })}
                                minDate={formik.values.startDate ? new Date(formik.values.startDate) : undefined}
                                onChange={formik.handleChange}
                            />

                            <InputNumberField
                                id="priority"
                                inputId="priority"
                                type="text"
                                showButtons
                                value={formik.values.priority}
                                required
                                onValueChange={formik.handleChange}
                                labelName="priority"
                                labelText="Priority"
                                getFormErrorMessage={() => (
                                    <ErrorField formik={formik} name="priority" />
                                )}
                                min={0}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "priority"),
                                })}
                            />


                            <InputNumberField
                                id="limitValue"
                                inputId="limitValue"
                                type="text"
                                showButtons
                                value={formik.values.limitValue}
                                required
                                onValueChange={formik.handleChange}
                                labelName="limitValue"
                                labelText="Number of days to show"
                                getFormErrorMessage={() => (
                                    <ErrorField formik={formik} name="limitValue" />
                                )}
                                min={0}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "limitValue"),
                                })}
                            />

                            <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Filters</div>

                            <InputNumberField
                                id="inviteCountUpperLimit"
                                inputId="inviteCountUpperLimit"
                                type="text"
                                showButtons
                                value={formik.values.inviteCountUpperLimit}
                                required
                                onValueChange={formik.handleChange}
                                labelName="inviteCountUpperLimit"
                                labelText="Min Invite Count"
                                getFormErrorMessage={() => (
                                    <ErrorField formik={formik} name="inviteCountUpperLimit" />
                                )}
                                min={0}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "inviteCountUpperLimit"),
                                })}
                            />

                            <InputNumberField
                                id="moneyAmountMin"
                                inputId="moneyAmountMin"
                                type="text"
                                showButtons
                                value={formik.values.moneyAmountMin}
                                required
                                onValueChange={formik.handleChange}
                                labelName="moneyAmountMin"
                                labelText="Min Money Amount"
                                getFormErrorMessage={() => (
                                    <ErrorField formik={formik} name="moneyAmountMin" />
                                )}
                                min={0}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "moneyAmountMin"),
                                })}
                            />

                            <InputNumberField
                                id="moneyAmountMax"
                                inputId="moneyAmountMax"
                                type="text"
                                showButtons
                                value={formik.values.moneyAmountMax}
                                required
                                onValueChange={formik.handleChange}
                                labelName="moneyAmountMax"
                                labelText="Max Money Amount"
                                getFormErrorMessage={() => (
                                    <ErrorField formik={formik} name="moneyAmountMax" />
                                )}
                                min={0}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "moneyAmountMax"),
                                })}
                            />
                        </>
                    ) : (
                        <>
                            <InputField
                                id="titleScnd"
                                value={formik.values.titleScnd ?? ''}
                                type="text"
                                visible={formik.values.popupType === POPUP_TYPES.TXT || formik.values.popupType === POPUP_TYPES.PIR || formik.values.popupType === POPUP_TYPES.PIL}
                                required
                                disabled={!isSendPopup}
                                onChange={formik.handleChange}
                                labelName="titleScnd"
                                labelText="Title Second"
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"titleScnd"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "titleScnd"),
                                })}
                            />
                            <InputField
                                id="textScnd"
                                value={formik.values.textScnd ?? ''}
                                type="text"
                                visible={formik.values.popupType === POPUP_TYPES.TXT}
                                required
                                disabled={!isSendPopup}
                                onChange={formik.handleChange}
                                labelName="textScnd"
                                labelText="Text Second"
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"textScnd"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "textScnd"),
                                })}
                            />
                            <InputField
                                id="linkScnd"
                                value={formik.values.linkScnd ?? ''}
                                type="text"
                                visible={formik.values.popupType === POPUP_TYPES.PIL || formik.values.popupType === POPUP_TYPES.FSIL}
                                required
                                disabled={!isSendPopup}
                                onChange={formik.handleChange}
                                labelName="linkScnd"
                                labelText="Link Second"
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"linkScnd"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "linkScnd"),
                                })}
                            />

                            <InputField
                                id="videoUrlScnd"
                                value={formik.values.videoUrlScnd ?? ''}
                                type="text"
                                visible={formik.values.popupType === POPUP_TYPES.VID}
                                required
                                disabled={!isSendPopup}
                                onChange={formik.handleChange}
                                labelName="videoUrlScnd"
                                labelText="Video Url Second(Youtube)"
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"videoUrlScnd"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "videoUrlScnd"),
                                })}
                            />

                            <InputField
                                id="imageUrlScnd"
                                value={formik.values.imageUrlScnd ?? ''}
                                type="text"
                                visible={formik.values.popupType === POPUP_TYPES.PIL || formik.values.popupType === POPUP_TYPES.FSIL || formik.values.popupType === POPUP_TYPES.PIR || formik.values.popupType === POPUP_TYPES.FSIR}
                                required
                                disabled={!isSendPopup}
                                readOnly
                                labelName="imageUrlScnd"
                                labelText="Image Url Second"
                                getFormErrorMessage={() => <ErrorField formik={formik} name={"imageUrlScnd"} />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "imageUrlScnd")
                                })}
                                onClick={() => openImageDialog("imageUrlScnd")}
                            />
                        </>
                    )}
                </form>
            </Dialog>

            <PopupProductSelectionDialog
                visible={popupProductSelectionDialog}
                onHide={hidePopupProductSelectionDialog}
                onProductSelect={handleProductSelect}
            />

            <PopupCampaignSelectionDialog
                visible={popupCampaignSelectionDialog}
                onHide={hidePopupCampaignSelectionDialog}
                onCampaignSelect={handleCampaignSelect}
            />

            <GeneralImageUploadDialog
                visible={imageDialog}
                onHide={hideImageDialog}
                saveImage={saveImage}
                imageUrl={currentImageField === "imageUrl" ? formik.values.imageUrl ?? '' : formik.values.imageUrlScnd ?? ''}
                isLoading={uploadGeneralImageIsLoading}
            />

        </>
    );
};

export default PopupDialog;
