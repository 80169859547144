import { Toast } from "primereact";
import { updatePopupStatus } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";
import { STATUS } from "@/constants";

export const useUpdatePopupStatusData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: updatePopupStatusIsLoading,
        isSuccess: updatePopupStatusIsSuccess,
    } = useMutation(async ({ popupId, status }: {
        popupId: bigint, status: STATUS
    }) => await updatePopupStatus(popupId, status), {
        onSuccess: () => {
            queryClient.invalidateQueries("popupHistory");
            toastSuccess(toast, "Popup status updated");
        },
        onError: (error: any) => {
            let errorMessage = "Popup status not updated";
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            toastError(toast, `${errorMessage}`);
        },
    });
    return {
        mutate,
        updatePopupStatusIsLoading,
        updatePopupStatusIsSuccess,
    };
};
