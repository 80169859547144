import {
    IMbi,
    IEcpm,
    IUser,
    IStock,
    IProduct,
    ISupplier,
    ICategory,
    ICampaign,
    IFootnote,
    ICustomer,
    ILoginFields,
    INotificationMessage,
    IPopup,
} from "@/models";
import {
    ROLE,
    STATUS,
} from "./enums";

export const initialCustomer: ICustomer = {
    id: null,
    guid: "",
    username: "",
    name: "",
    surname: "",
    email: "",
    emailConfirm: false,
    emailConfirmCode: "",
    password: "",
    totalStep: 0,
    averageStep: 0,
    gsmno: "",
    address: "",
    birthDate: null,
    gender: null,
    city: "",
    status: "",
    type: "",
    createDate: null,
    processDate: null,
    moneyAmount: 0,
    overallIncome: null,
    totalElements: 0,
    totalLoginDayCount: null,
    totalPassedDayFromRegister: null,
};

export const initialLogin: ILoginFields = {
    email: "",
    password: "",
};

export const initialNotificationMessage: INotificationMessage = {
    id: null,
    title: null,
    text: null,
    titleScnd: null,
    textScnd: null,
    msgCode: null,
    amount: null,
};

export const initialFootnote: IFootnote = {
    id: null,
    name: null,
    name2lng: null,
    description: null,
    description2lng: null,
};

export const initialProduct: Omit<IProduct, "imageUrl"> = {
    id: null,
    name: "",
    type: null,
    brand: "",
    model: "",
    longDesc: "",
    shortDesc: "",
    sellingPoint: 0,
    sequence: 0,
    stockQuantity: 0,
    categories: [],
    images: [
        {
            filePath: "",
            id: 0,
            name: "",
            type: "",
            isMainPoster: 0,
        },
    ],
    yearlyPurchaseLimit: 1,
    status: STATUS.INACTIVE,
    name2lgn: "",
    brand2lng: "",
    longDesc2lng: "",
    shortDesc2lng: "",
    productLink: "",
    labelText: null,
    labelColor: null,
    showStockAmount: null,
    footNote: null,
    footNoteName: null,
    footNote2lng: null,
    imageSizeType: null,
    drawDate: null,
    drawJoinEndDate: null,
    linkText: null,
    linkTextScnd: null,
    targetUrl: null,
    targetUrlScnd: null,
};

export const initialMbi: IMbi = {
    action: null,
    description: "",
    descriptionScnd: "",
    id: null,
    imageUrl: "",
    sequence: 0,
    status: STATUS.INACTIVE,
    targetId: 0,
    targetUrl: null,
    targetUrlScnd: null,
    title: "",
    titleScnd: "",
    type: null,
    product: null,
    campaign: "",
    navbarEnabled: 1,
    headerEnabled: 1,
};

export const initialUser: IUser = {
    id: null,
    firebaseUid: "",
    email: "",
    password: "",
    name: "",
    surname: "",
    birthDate: null,
    type: "",
    role: ROLE.OPERATOR,
};

export const initialSupplier: ISupplier = {
    id: null,
    name: "",
    adress: "",
    telNo: "",
    type: "",
};

export const initialEcpm: IEcpm = {
    id: null,
    date: null,
    ecpm: null,
};

export const initialPopup: IPopup = {
    id: null,
    title: null,
    titleScnd: null,
    text: null,
    textScnd: null,
    status: STATUS.INACTIVE,
    link: null,
    linkScnd: null,
    imageUrl: null,
    imageUrlScnd: null,
    videoUrl: null,
    videoUrlScnd: null,
    popupType: null,
    skipSecond: 0,
    totalElements: 0,
    startDate: null,
    endDate: null,
    moneyAmountMin: null,
    moneyAmountMax: null,
    routeTargetId: null,
    dailyFrequency: 1,
    priority: 0,
    limitValue: 1,
    inviteCountUpperLimit: null,
    readPopUpCount: 0,
    readUniquePopUpCount: 0,
};

export const initialCategory: ICategory = {
    id: null,
    title: "",
    description: "",
    productList: null,
    title2lng: "",
    description2lng: "",
};

export const initialStock: IStock = {
    id: null,
    date: null,
    billNo: "",
    unitPrice: 0,
    amount: 0,
    total: 0,
    product: initialProduct,
    productList: [],
    supplier: null,
};

export const initialCampaign: Omit<ICampaign, "imageUrl"> = {
    id: null,
    name: "",
    type: null,
    description: "",
    amount: 0,
    sellingPoint: 0,
    totalDrawTicketCount: 0,
    bonus: 0,
    status: STATUS.INACTIVE,
    stepCountLimit: 0,
    sequence: 0,
    limitValue: 0,
    name2lgn: "",
    description2lng: "",
    drawDate: null,
    drawJoinEndDate: null,
    drawCode: null,
    prizeText: null,
    prizeTextScnd: null,
    subInfoText: null,
    subInfoTextScnd: null,
    linkText: null,
    linkTextScnd: null,
    targetUrl: null,
    targetUrlScnd: null,
    period: "",
    directPopUpImg1: null,
    directPopUpImg2: null,
    directPopUpColorCode1: null,
    directPopUpColorCode2: null,
    directPopUpButtonColorCode: null,
    isClickable: true,
};
