import { IPopup } from "@/models";
import { Toast } from "primereact";
import { updatePopup } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useUpdatePopupData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: updatePopupIsLoading,
        isSuccess: updatePopupIsSuccess,
    } = useMutation(async (popup: IPopup) => await updatePopup(popup), {
        onSuccess: () => {
            queryClient.invalidateQueries("popupHistory");
            toastSuccess(toast, "Popup updated");
        },
        onError: (error: any) => {
            let errorMessage = "Popup not updated";
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            toastError(toast, `${errorMessage}`);
        },
    });
    return {
        mutate,
        updatePopupIsLoading,
        updatePopupIsSuccess,
    };
};
