// export const getBaseUrl = () => {
//   let url;
//   switch (process.env.NODE_ENV) {
//     case "production":
//       url = "https://www.chalokamao.com/api";
//       break;
//     case "development":
//       url = "https://www.chalokamao.com/api";
//       break;
//     default:
//       url = "https://www.chalokamao.com/api";
//   }
//   return url;
// };

export const getBaseUrl = () => {
  let url;
  switch (process.env.NODE_ENV) {
    case "production":
      url = "https://www.ck-app.site/api";
      break;
    case "development":
      url = "https://www.ck-app.site/api";
      break;
    default:
      url = "https://www.ck-app.site/api";
  }
  return url;
};

// export const getBaseUrl = () => {
//   let url;
//   switch (process.env.NODE_ENV) {
//     case "production":
//       url = "http://194.163.160.61:5555";
//       break;
//     case "development":
//       url = "http://194.163.160.61:5555";
//       break;
//     default:
//       url = "http://194.163.160.61:5555";
//   }
//   return url;
// };

